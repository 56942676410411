import { hashHistory } from "../App";
import serializeError from "../utils/serializeError";

// const apiBase = "https://api-av.xcpep.com/";
// const apiBase = "https://api-daimler.xcpep.com/";
// const apiBase = "https://api-tvs.xcpep.com/";
// const apiBase = "https://api-olaelectric.xcpep.com/";
// const apiBase = "https://api-stage2.xcpep.com/";

const currentUrl = window.location.href;
const getApiBAse = () => {
    let Apiurl = "";
    if ( typeof currentUrl !== "undefined" ) {
        if ( currentUrl.includes( "tvs-companion.xcpep.com" ) ) {
            Apiurl = "https://api-tvsm.xcpep.com/";
        } else if ( currentUrl.includes( "daimler-companion.xcpep.com" ) ) {
            Apiurl = "https://api-dam.xcpep.com/";
        } else if ( currentUrl.includes( "app-companion.xcpep.com" ) ) {
            Apiurl = "https://api.xcpep.com/";
        } else if ( currentUrl.includes( "mnm-companion.xcpep.com" ) ) {
            Apiurl = "https://api-mnm.xcpep.com/";
        } else if ( currentUrl.includes( "hmie-companion.xcpep.com" ) ) {
            Apiurl = "https://api-hmie.xcpep.com/";
        } else if ( currentUrl.includes( "bal-companion.xcpep.com" ) ) {
            Apiurl = "https://api-ba.xcpep.com/";
        } else if ( currentUrl.includes( "ae-companion.xcpep.com" ) ) {
            Apiurl = "https://api-ae.xcpep.com/";
        } else if ( currentUrl.includes( "olaelectric-companion.xcpep.com" ) ) {
            Apiurl = "https://api-ol.xcpep.com/";
        } else if ( currentUrl.includes( "mlmm-companion.xcpep.com" ) ) {
            Apiurl = "https://api-mlmm.xcpep.com/";
        } else if ( currentUrl.includes( "subscription-companion.xcpep.com" ) ) {
            Apiurl = "https://api-subscription.xcpep.com/";
        } else if ( currentUrl.includes( "mck-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-mck.xcpep.com/";
        } else if ( currentUrl.includes( "av-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-av.xcpep.com/";
        } else if ( currentUrl.includes( "cnhi-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-cnhi.xcpep.com/";
        } else if ( currentUrl.includes( "tm-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-tm.xcpep.com/";
        } else if ( currentUrl.includes( "ucal-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-ucal.xcpep.com/";
        } else if ( currentUrl.includes( "mgi-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-mgi.xcpep.com/";
        } else if ( currentUrl.includes( "gcl-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-gcl.xcpep.com/";
        } else if ( currentUrl.includes( "hrid-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-hrid.xcpep.com/";
        } else if ( currentUrl.includes( "bsl-companion.xcpep.com/" ) ) {
            Apiurl = "https://api-bsl.xcpep.com/";
        }
    }
    return Apiurl;
};
// const apiBase = getApiBAse();
const apiBase = "https://api-stage2.xcpep.com/";
class Api {
    constructor( apiBase ) {
        this._apiBase = apiBase;
    }

    get ( url, params = {} ) {
        return fetch( this._getUrl( url, params ), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + localStorage.getItem( "token" ),
            },
        } ).then( this._errorHandler );
    }

    post ( url, data = {}, params = {} ) {
        return fetch( this._getUrl( url, params ), {
            body: this._getBody( data ),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + localStorage.getItem( "token" ),
            },
        } ).then( this._errorHandler );
    }

    put ( url, data = {}, params = {} ) {
        return fetch( this._getUrl( url, params ), {
            body: this._getBody( data ),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + localStorage.getItem( "token" ),
            },
        } ).then( this._errorHandler );
    }

    delete ( url, data = {}, params = {} ) {
        return fetch( this._getUrl( url, params ), {
            body: this._getBody( data ),
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + localStorage.getItem( "token" ),
            },
        } ).then( this._errorHandler );
    }

    toJSON ( response ) {
        return response.json();
    }

    toBlob ( response ) {
        return response.blob();
    }

    async _errorHandler ( response ) {
        if ( response.ok ) return response;

        // checkForAuthenticationFailed
        if ( response.status === 401 ) {
            localStorage.clear();
            hashHistory.push( "/" );
        }

        const error = await response.clone().json();
        throw new Error( serializeError( error, response.statusText ) );
    }

    _getBody ( data ) {
        return data instanceof FormData ? data : JSON.stringify( data );
    }

    _getUrl ( relativeUrl, params = {} ) {
        return `${ this._apiBase }${ relativeUrl }?${ this._getParamsString(
            params
        ) }`;
    }

    _getParamsString ( params ) {
        return new URLSearchParams( params );
    }
}

const API = new Api( apiBase );

export default API;
